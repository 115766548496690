<template>
  <b-form @submit.stop.prevent="searchCustomer">
    <b-input-group>
      <b-input-group-prepend is-text
        ><i class="fa fa-search"></i
      ></b-input-group-prepend>
      <b-form-input
        v-model="phoneNumber"
        placeholder="Утасны дугаар/Регистер"
      ></b-form-input>
      <b-input-group-append
        >
        <b-button variant="primary" type="submit" :disabled="isLoading"
          >
          <span v-if="!isLoading">Хайх</span>
          <span v-else>Уншиж байна</span>
          </b-button
        >
        
        </b-input-group-append
      >
    </b-input-group>
  </b-form>
</template>

<script>
  export default {
    data: function () {
      return {
          isLoading: false,
          phoneNumber: ""
      }
    },
    methods: {
      searchCustomer: function() {
        this.$data.isLoading = true
        this.$http.get(this.$config.API_URL + 'CustomerWebService/get_customer_by_phonenumber', {
          params: {
            phone_number: this.$data.phoneNumber
          },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true
        })
        .then((response) => {
          this.isLoading = false;
          if (response.data.responseResultType === 'FAILURE') {
            this.showToast(
              "Анхаар",
              "Хэрэглэгч олдсонгүй",
              "warning"
            );
          } else {
            this.$data.phoneNumber = ''
            let route = this.$router.resolve({ path: "/admin/customers/" + response.data.id + "/detail" });
            window.open(route.href);
          }
        }, response => {
          this.isLoading = false;
        })
      }
    }
  }
</script>
