<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="/">
        <img class="navbar-brand-full" src="/img/logo.jpg" width="89" height="25" alt="Numur Admin">
        <img class="navbar-brand-minimized" src="/img/logo.jpg" width="30" height="30" alt="Numur Admin">
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg" />
      <b-navbar-nav class="d-md-down-none">
        <CustomerSearchBar />
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <DefaultHeaderDropdownActionExtend/>
        <DefaultHeaderDropdownAction/>
        <DefaultHeaderDropdownAccnt/>
      </b-navbar-nav>
      <AsideToggler class="d-none d-lg-block" /> 
      <!-- <AsideToggler class="d-lg-none" mobile /> -->
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list"/>
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <!-- <AppAside fixed> -->
        <!--aside-->
        <!-- <DefaultAside/> -->
      <!-- </AppAside> -->
    </div>
    <TheFooter>
      <!--footer-->
      <div class="ml-auto">
        <span class="mr-1">Хөгжүүлсэн</span>
        <a href="https://numurcredit.mn">Нөмөр кредит</a>
      </div>
    </TheFooter>
  </div>
</template>

<script>
import nav from '@/_nav'
import { Header as AppHeader, SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav, Aside as AppAside, AsideToggler, Footer as TheFooter, Breadcrumb } from '@coreui/vue'
import DefaultAside from './DefaultAside'
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'
import DefaultHeaderDropdownAction from './DefaultHeaderDropdownAction'
import DefaultHeaderDropdownActionExtend from './DefaultHeaderDropdownActionExtend'
import CustomerSearchBar from '../views/admin/Customer/Customer/CustomerSearchBar'

export default {
  name: 'DefaultContainer',
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdownAccnt,
    DefaultHeaderDropdownAction,
    DefaultHeaderDropdownActionExtend,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
    CustomerSearchBar
  },
  data () {
    return {
      nav: nav.items,
    }
  },
  created: function() {
     this.checkSession();
     if(!this.guard(this.$route)) {
       this.$router.push('/dashboard')
     }
  },
  afterRouteUpdate(to, from, next) {
    alert('sadfasdf')
    next();
  },
  beforeRouteUpdate(to, from, next) {
    
    this.checkSession();
    if(this.guard(to)) {
      next();
    } else {
      this.$bvToast.toast('Хандах эрх байхгүй байна', {
            title: 'Анхаар !!!',
            variant: 'warning',
            solid: true,
            autoHideDelay: 5000,
            appendToast: true
          })
        
      this.$router.push('/dashboard')
    }
  },
  computed: {
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.label || route.meta.label )
    }
  },
  methods: {
    
  }
}
</script>
