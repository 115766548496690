<template>
  <AppHeaderDropdown right >
    <template slot="header">
      Зээл сунгалт
    </template>
    <template slot="dropdown">
      <b-dropdown-item :to="{name:'admin.customer-loan-accounts.extend-generate'}">Тооцоолуур</b-dropdown-item>
      <b-dropdown-item v-if="this.checkPermission('admin.loan.extend')" :to="{name:'admin.customer-loan-accounts.extend'}">Зээл сунгах</b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
export default {
  name: 'DefaultHeaderDropdownAccnt',
  components: {
    AppHeaderDropdown
  },
  data: () => {
    return { }
  },
  methods: {
  }
}
</script>
